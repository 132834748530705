import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faPinterestP,
  faTwitter,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons'

const Wrapper = styled.footer`
  display: grid;
  grid-template-columns: 1fr;
  background: ${props => props.theme.colors.base};
  width: 100%;
`
const FooterContainer = styled.div``
const Instagram = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`
const Picture = styled.div`
  display: grid;
`

const PageLinks = styled.div`
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin: 50px;
  font-weight: 300;
  a {
    padding: 15px;
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
    &:hover {
      font-weight: bold;
    }
  }
`

const SocialIcons = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 75px 75px 75px 75px;
  grid-template-rows: 75px;
  font-size: 50px;
  justify-content: center;
  justify-items: center;
  a {
    display: grid;
    border: 1px solid;
    border-color: ${props => props.theme.colors.secondary};
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
    color: ${props => props.theme.colors.secondary};
    border-radius: 5px;
  }
  a:hover {
    background: ${props => props.theme.colors.tertiary};
    color: white;
  }
`
const Legal = styled.div`
  white-space: nowrap;
  margin-top: 60px;
  margin-bottom: 60px;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 12px;
  color: ${props => props.theme.colors.secondary};
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
  }
  h6 {
    padding: 5px;
    white-space: nowrap;
  }
`
const activeLinkStyle = {
  color: '#333',
  fontWeight: 'bold',
}

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        allInstaNode {
          edges {
            node {
              id
              original
              caption
              localFile {
                childImageSharp {
                  fluid(maxWidth: 250, maxHeight: 250, cropFocus: ATTENTION) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              thumbnails {
                src
                config_width
                config_height
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <FooterContainer>
          <Instagram>
            {data.allInstaNode.edges.map(({ node: instagram }) => (
              <Picture key={instagram.id}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://instagram.com/p/${instagram.id}`}
                >
                  <Img
                    fluid={instagram.localFile.childImageSharp.fluid}
                    title={instagram.caption}
                    alt={instagram.caption}
                  />
                </a>
              </Picture>
            ))}
          </Instagram>
          <PageLinks>
            <Link to="/" activeStyle={activeLinkStyle}>
              <h4>Home</h4>
            </Link>
            <Link to="/category/beauty" activeStyle={activeLinkStyle}>
              <h4>Beauty</h4>
            </Link>
            <Link to="/category/fashion" activeStyle={activeLinkStyle}>
              <h4>Fashion</h4>
            </Link>
            <Link to="/category/lifestyle" activeStyle={activeLinkStyle}>
              <h4>Lifestyle</h4>
            </Link>
            <Link to="/about" activeStyle={activeLinkStyle}>
              <h4>About</h4>
            </Link>
            <Link to="/contact" activeStyle={activeLinkStyle}>
              <h4>Contact</h4>
            </Link>
          </PageLinks>
          <SocialIcons>
            <a
              href="https://www.instagram.com/kindredstyles/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.pinterest.com/kindredstyles/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Pinterest"
            >
              <FontAwesomeIcon icon={faPinterestP} />
            </a>
            <a
              href="https://www.twitter.com/kindred_styles/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Twitter"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="https://www.facebook.com/kindredstyles/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </SocialIcons>
          <Legal>
            <h6>
              <Link to="/">TERMS & CONDITIONS</Link> |{' '}
              {
                // https://www.jadeoak.com/terms-conditions-blog-needs/
              }
              <Link to="/privacy-policy">PRIVACY</Link> |{' '}
              <Link to="/">DISCLAIMER</Link>
              {
                // https://termsfeed.com/blog/blog-disclaimer/#How_to_write_an_8220Affiliate_Disclaimer8221
              }
            </h6>
            <h6>
              COPYRIGHT &copy; KINDRED STYLES 2019.
              <br />
              ALL RIGHTS RESERVED.
            </h6>
          </Legal>
        </FooterContainer>
      </Wrapper>
    )}
  />
)

export default Footer
