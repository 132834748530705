import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Index } from 'elasticlunr'
import NavMenuButton from './NavMenuButton'
import LogoBG from '../images/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const Header = styled.header`
  background: ${props => props.theme.colors.base};
  width: 100%;
  position: fixed;
  z-index: 999;
  .nav-button {
    visibility: hidden;
  }
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    z-index: 999;
    bottom: 0;
    .nav-link__container {
      visibility: hidden;
    }
    .nav-button {
      visibility: visible;
      margin-right: 25px;
    }
    .nav-logo {
      visibility: hidden;
      img {
        display: none;
      }
    }
  }
`
const SearchButton = styled.button`
  cursor: pointer;
  top: 0;
  padding: 0;
`

const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 300;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  .nav-link__container {
    width: 100%;
    display: grid;
    align-content: center;
    padding: 1.75em 0;
  }
  .nav-link__container.search-field {
    height: 5em;
    padding: 0;
  }
  .search-bar {
    display: grid;
    grid-template-columns: 6fr 0.5fr;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

  .nav-links {
    display: grid;
    grid-template-columns: repeat(6, 1fr) 0.5fr;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: #333;
    &:hover {
      font-weight: bold;
    }
  }
`
const LogoSVG = styled.img`
  height: 3.5rem;
  padding: 5px;
`
const SearchInput = styled.input`
  font-family: 'Poppins', sans-serif;
  height: 200%;
  letter-spacing: 2px;
  font-size: 0.8rem;
  font-weight: 300;
  background-color: white;
  border-radius: 5px;
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 5px;
  text-align: center;
`
const SearchResults = styled.div`
  background: white;
  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: inherit;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    width: 100%;
    a {
      text-decoration: none;
      text-align: center;
      color: ${props => props.theme.colors.secondary};
      width: 100%;
      height: 100%;
      padding: 15px;
      border-bottom: 1px solid #333;
      border-left: 1px solid #333;
      border-right: 1px solid #333;
    }
    li {
      font-family: 'Poppins', sans-serif;
      letter-spacing: 2px;
      font-size: 0.8rem;
      font-weight: 300;
      text-transform: uppercase;

      span {
        font-family: 'Signature', cursive;
        font-size: 2em;
        font-weight: 300;
        text-transform: none;
      }
    }
    a:hover {
      background: ${props => props.theme.colors.tertiary};
      color: white;
    }
  }
`
const TransparentBG = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  background: transparent;
  z-index: 500;
`

const activeLinkStyle = {
  color: '#000',
  fontWeight: 'bold',
}

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
      showSearch: false,
    }
  }

  toggleSearchBar = () => {
    this.setState(prevState => {
      return { showSearch: !prevState.showSearch, query: '', results: [] }
    })
  }
  toggleSearchBarKey = e => {
    if (this.state.showSearch === true) {
      if (e.key === 'Escape') {
        this.setState(prevState => {
          return { showSearch: !prevState.showSearch, query: '', results: [] }
        })
      }
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.toggleSearchBarKey, false)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.toggleSearchBarKey, false)
  }
  render() {
    let headerClasses = 'nav-link__container'
    if (this.state.showSearch) {
      headerClasses = 'nav-link__container search-field'
    }
    return (
      <Header>
        <Nav>
          <div className="nav-logo">
            {this.props.pathname !== '/' && (
              <Link to="/">
                <LogoSVG src={LogoBG} alt="Kindred Styles Logo" />
              </Link>
            )}
          </div>
          <div className={headerClasses}>
            {this.state.showSearch ? (
              <div className="search-bar">
                <SearchInput
                  type="text"
                  value={this.state.query}
                  onChange={this.search}
                  placeholder="Search..."
                />
                <SearchButton
                  onClick={this.toggleSearchBar}
                  role="button"
                  aria-label="Open Search Bar"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </SearchButton>
              </div>
            ) : (
              <ul className="nav-links">
                <li>
                  <Link to="/" activeStyle={activeLinkStyle}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/category/beauty" activeStyle={activeLinkStyle}>
                    Beauty
                  </Link>
                </li>
                <li>
                  <Link to="/category/fashion" activeStyle={activeLinkStyle}>
                    Fashion
                  </Link>
                </li>
                <li>
                  <Link to="/category/lifestyle" activeStyle={activeLinkStyle}>
                    Lifestyle
                  </Link>
                </li>
                <li>
                  <Link to="/about/" activeStyle={activeLinkStyle}>
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/contact/" activeStyle={activeLinkStyle}>
                    Contact
                  </Link>
                </li>
                <li>
                  <SearchButton
                    onClick={this.toggleSearchBar}
                    onKeyDown={this.toggleSearchBarKey}
                    role="button"
                    aria-label="Open Search Bar"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </SearchButton>
                </li>
              </ul>
            )}
          </div>
          <div className="nav-button">
            <NavMenuButton
              click={this.props.drawerClickHandler}
              show={this.props.show}
            />
          </div>
        </Nav>
        <SearchResults>
          {this.state.results.length > 0 && (
            <ul>
              {this.state.results.map(post => (
                <Link to={'/' + post.slug} key={post.id}>
                  <li>
                    <span>{post.title}</span>
                    {': ' + post.tag.join(`, `)}
                  </li>
                </Link>
              ))}
            </ul>
          )}
        </SearchResults>
        {this.state.showSearch && (
          <TransparentBG onClick={this.toggleSearchBar} />
        )}
      </Header>
    )
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = e => {
    const query = e.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {})
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}
export default Menu
