const theme = {
  colors: {
    base: '#f8f3f1', // Pink
    secondary: '#333', // Black
    tertiary: '#c2a7b7', // Light Gray
    highlight: '#5b8bf7', // Light Blue
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '750px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

export default theme
