import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faPinterestP,
  faTwitter,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons'
import LogoBG from '../images/logo.svg'

const MenuDrawer = styled.nav`
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    display: none;
  }
  ul {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    width: 100%;
    justify-content: center;
    justify-items: center;
    .menu-item {
      margin: 25px;
      .page-link {
        text-decoration: none;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        letter-spacing: 2px;
        color: ${props => props.theme.colors.secondary};
        text-transform: uppercase;
      }
    }
  }
  .side-drawer {
    height: 100%;
    background: white;
    z-index: 998;
    position: fixed;
    width: 100%;
    top: 0;
    padding-top: 20%;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
  }
  .side-drawer.open {
    transform: translateY(0);
  }
`

const MobileSocialIcons = styled.li`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  justify-items: center;
  font-size: 30px;
  align-content: center;
  a {
    margin: 0 15px;
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
  }
`
const LogoSVG = styled.img`
  height: 3.5rem;
  padding: 5px;
`

const activeLinkStyle = {
  color: '#333',
  fontWeight: 'bold',
}

const MobileMenu = props => {
  let drawerClasses = 'side-drawer'
  if (props.show) {
    drawerClasses = 'side-drawer open'
  }

  return (
    <MenuDrawer>
      <div className={drawerClasses}>
        <ul>
          <li>
            <Link to="/" activeStyle={activeLinkStyle} className="page-link">
              <LogoSVG src={LogoBG} alt="Kindred Styles Logo" />
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/" activeStyle={activeLinkStyle} className="page-link">
              Home
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/category/beauty"
              activeStyle={activeLinkStyle}
              className="page-link"
            >
              Beauty
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/category/fashion"
              activeStyle={activeLinkStyle}
              className="page-link"
            >
              Fashion
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/category/lifestyle"
              activeStyle={activeLinkStyle}
              className="page-link"
            >
              Lifestyle
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/about/"
              activeStyle={activeLinkStyle}
              className="page-link"
            >
              About
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/contact/"
              activeStyle={activeLinkStyle}
              className="page-link"
            >
              Contact
            </Link>
          </li>
          <MobileSocialIcons>
            <a
              href="https://www.instagram.com/kindredstyles/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.pinterest.com/kindredstyles/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Pinterest"
            >
              <FontAwesomeIcon icon={faPinterestP} />
            </a>
            <a
              href="https://www.twitter.com/kindred_styles/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Twitter"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="https://www.facebook.com/kindredstyles/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </MobileSocialIcons>
        </ul>
      </div>
    </MenuDrawer>
  )
}

export default MobileMenu
