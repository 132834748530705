import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Index } from 'elasticlunr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faPinterestP,
  faTwitter,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons'
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import LogoKS from '../images/small-logo.svg'

const MobileHeaderWrapper = styled.div`
  background: ${props => props.theme.colors.base};
  width: 100%;
  display: none;
  grid-template-columns: 3fr 3fr;
  padding: 0 2rem;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    z-index: 998;
    top: 0;
    display: grid;
  }

  .mobile-header__links {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-content: center;
    justify-items: right;
    padding: 15px 0;
    width: 100%;
  }
  .mobile-header__logo {
    display: grid;
    align-items: center;
    justify-content: left;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
  }
`
const MobileSearchWrapper = styled.div`
  background: ${props => props.theme.colors.base};
  width: 100%;
  visibility: hidden;
  display: grid;
  grid-template-columns: 6fr 1fr;
  padding: 0 2rem;
  height: 4em;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    z-index: 998;
    top: 0;
    visibility: visible;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
  }
`
const SearchInput = styled.input`
  font-family: 'Poppins', sans-serif;
  height: 75%;
  letter-spacing: 2px;
  font-size: 0.8rem;
  font-weight: 300;
  background-color: white;
  border-radius: 5px;
  padding: 0;
  margin: auto 0;
  width: 100%;
  border-radius: 5px;
  text-align: center;
`
const SearchButton = styled.button`
  cursor: pointer;
  margin: 0;
  padding: 0;
`
const SearchResults = styled.div`
  background: white;
  position: absolute;
  z-index: 999;
  width: 100%;
  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: inherit;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    width: 100%;
    a {
      text-decoration: none;
      text-align: center;
      color: ${props => props.theme.colors.secondary};
      width: 100%;
      height: 100%;
      padding: 15px;
      border-bottom: 1px solid #333;
      border-left: 1px solid #333;
      border-right: 1px solid #333;
    }
    li {
      font-family: 'Poppins', sans-serif;
      letter-spacing: 2px;
      font-size: 0.8rem;
      font-weight: 300;
      text-transform: uppercase;

      span {
        font-family: 'Signature', cursive;
        font-size: 2em;
        font-weight: 300;
        text-transform: none;
      }
    }
    a:hover {
      background: ${props => props.theme.colors.tertiary};
      color: white;
    }
  }
`
const KSLogo = styled.img`
  height: 40px;
  width: 40px;
`
const TransparentBG = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 500;
`

class MobileHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
      showSearch: false,
    }
  }

  toggleSearchBar = () => {
    this.setState(prevState => {
      return { showSearch: !prevState.showSearch, query: '', results: [] }
    })
  }
  render() {
    return (
      <div>
        {this.state.showSearch ? (
          <div>
            <MobileSearchWrapper>
              <SearchInput
                type="text"
                value={this.state.query}
                onChange={this.search}
                placeholder="Search..."
              />
              <SearchButton
                onClick={this.toggleSearchBar}
                role="button"
                aria-label="Open Search Bar"
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </SearchButton>
            </MobileSearchWrapper>
            <TransparentBG onClick={this.toggleSearchBar} />
          </div>
        ) : (
          <MobileHeaderWrapper>
            <div className="mobile-header__logo">
              <Link to="/">
                <KSLogo src={LogoKS} alt="Kindred Styles Logo" />
              </Link>
            </div>
            <ul className="mobile-header__links">
              <li>
                <SearchButton
                  onClick={this.toggleSearchBar}
                  role="button"
                  aria-label="Open Search Bar"
                >
                  <FontAwesomeIcon icon={faSearch} />
                </SearchButton>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/kindredstyles/"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Instagram"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.com/kindredstyles/"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Pinterest"
                >
                  <FontAwesomeIcon icon={faPinterestP} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/kindred_styles/"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/kindredstyles/"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
            </ul>
          </MobileHeaderWrapper>
        )}
        <SearchResults>
          {this.state.results.length > 0 && (
            <ul>
              {this.state.results.map(post => (
                <Link to={'/' + post.slug} key={post.id}>
                  <li>
                    <span>{post.title}</span>
                    {': ' + post.tag.join(`, `)}
                  </li>
                </Link>
              ))}
            </ul>
          )}
        </SearchResults>
      </div>
    )
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = e => {
    const query = e.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {})
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}
export default MobileHeader
