import React from 'react'
import styled from 'styled-components'

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  margin-right: 1rem;

  #nav-icon {
    height: 24px;
    width: 30px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${props => props.theme.colors.secondary};
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon span:nth-child(1) {
    top: 2px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon span:nth-child(2) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon span:nth-child(3) {
    top: 22px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 2px;
    left: 4px;
  }

  #nav-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 23px;
    left: 4px;
  }
`

const NavMenuButton = props => {
  let open = ''

  if (props.show) {
    open = 'open'
  }
  return (
    <ToggleButton
      onClick={props.click}
      role="button"
      aria-label="Menu Open And Close"
    >
      <div id="nav-icon" className={open}>
        <span />
        <span />
        <span />
      </div>
    </ToggleButton>
  )
}

export default NavMenuButton
