import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.png'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import MobileMenu from './MobileMenu'
import MobileHeader from './MobileHeader'

import 'typeface-halant'
import 'typeface-poppins'

class Template extends Component {
  state = {
    drawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { drawerOpen: !prevState.drawerOpen }
    })
  }
  render() {
    const { children, pathname } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SearchIndexQuery {
            siteSearchIndex {
              index
            }
          }
        `}
        render={data => (
          <div className="siteRoot">
            <Helmet htmlAttributes={{ lang: 'en' }}>
              <title>{config.siteTitle}</title>
              <meta charSet="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <link rel="icon" href={favicon} />
            </Helmet>

            <ThemeProvider theme={theme}>
              <>
                <div className="siteContent">
                  <MobileHeader
                    pathname={pathname}
                    searchIndex={data.siteSearchIndex.index}
                  />
                  <Menu
                    drawerClickHandler={this.drawerToggleClickHandler}
                    show={this.state.drawerOpen}
                    pathname={pathname}
                    searchIndex={data.siteSearchIndex.index}
                  />
                  <MobileMenu show={this.state.drawerOpen} />
                  {children}
                </div>
                <Footer />
              </>
            </ThemeProvider>
            <GlobalStyle />
          </div>
        )}
      />
    )
  }
}
export default Template
